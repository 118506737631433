@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "quill-emoji/dist/quill-emoji.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/leaflet-draw/dist/leaflet.draw.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@font-face {
  font-family: 'DM Sans';
  src: url('/assets/fonts/DMSans-Bold.ttf');
}
@font-face {
  font-family: 'General Sans';
  src: url('/assets/fonts/DMSans-Regular.ttf');
}

/* You can add global styles to this file, and also import other style files */
:root {
  --client: #00ad52;
  --light-client: rgba(0, 173, 82, 0.15);
  --bg-primary-dark: #2a5339;
  --dark: #1c2436;
  --body-font: #454c5f;
  --border: #e8e7ee;
  --input-border: #cbcacf;
  --white-200: #f6f6f9;
  --red: #fa4d4d;
  --orange: #f4a62a;
  --light-red: #ffefeb;
  --light-orange: #fff7d6;
  --light-purple: #e7d6ff;
  --light-green: #d6ffd8;
  --light-green-2: #ebfa9e;
  --bs-danger-border-subtle:#f1aeb5;
  --h1: 68px;
  --h2: 48px;
  --h3: 40px;
  --h4: 30px;
  --h5: 24px;
  --h6: 20px;
  --bigp: 18px;
  --p: 16px;
  --button: 16px;
}

html,body{
  height: 100%;
  font-family: Lato;
  font-style: normal;
  font-size: 12px;
  color: #222831;
  height: 100%;
  margin: 0;
  padding: 0;
  background:#F5F5FA;
}


.mat-content {
  display: flex;
  align-items: center !important;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
}

@media print { 
  @page {
    size: 8.5in 11in !important;
  }
}


.apexcharts-legend-marker {
  margin-right: 11px !important;
}

.apexcharts-legend{
  justify-content: center !important;
}

.apexcharts-datalabel-label{
  display: none !important;
}

.apexcharts-legend-series {
  line-height: 32px !important;
  display: flex !important;
  margin: 0 !important;
}

.apexcharts-legend-series{
  align-items: center;
}

.apexcharts-legend-text {
  position: relative;
  font-size: 14px;
  color: #1C1D21 !important;
}

.mat-expansion-panel-header-title{
  align-items: center;
}

/* app-subscription-list, app-invoice-list{
  flex-direction: column; overflow: hidden; display: flex;
} */

/*For google places ngx*/ 
.pac-container {
  z-index: 99999 !important;
}

html.user-dashboard-bg,
body.user-dashboard-bg,
html.contact-list-bg,
body.contact-list-bg{
  background: #fff !important;
}
/* mouse over link */
a:hover {
  color: #4DF189;
}

/* selected link */
a:active {
  color: #4DF189;
}

.header-container{
  width: 100%;
  border-bottom: 1px solid #ECEEF5;
}
.container{
  width:1200px;
  margin: 0 auto;
}
.page{
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0;
  min-height: 100%;
  height: auto;
}
.header.home-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}
.header .logo{
  float:left
}
.header .action{
  float:right
}

.main{
  padding-top:100px
}
.main .page-title{
  font-size: 32px;font-weight:bold;text-align:center
}
.main .page-help{
  font-size:14px;
  color: #8083A3;text-align:center
}
.main .login-form{
  width: 420px;
  padding-top: 50px;
  margin: 0 auto;
}
.login-container .login-form{
  width: 389px;
  padding-top:5px;
  margin: 0px;
}
.login-container .confirmation-text{
  margin-top: 10px;
}
label{
  font-size:14px;color:#8083A3;
}
.form-control{
  font-size:16px;font-weight:bold;color:#1A1C1D;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  box-shadow:none;
  background: inherit;
}

.globe-button{
  display: inline-flex;
  background-color: #F6F6F9;
  font-size: 20px;
  line-height: 20px;
  margin-left: 11.5px;
  border-radius: 4px;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
}

.mat-button-menu-item{
  display: flex;
  align-items: center;
  gap: 10px;
}
.mat-button-menu-item span{
  font-size: 16px;
}

.mat-button-menu-item span.canada-lo{
  font-size: 18px;
  line-height: 18px;
}

.header-container{
  background-color: white;
}

.form-group .error .form-control{
  border-color:#FF0000;
}
.form-group .input_icon{
  float:right;position:relative;margin-top:-35px;padding-right: 5px;
}
.login-form .btn.btn-login{
  width:100%;
  font-size:16px;font-weight:bold;background:#4DF189;color: #063622;padding:10px;border-radius:10px;
}
.btn.btn-create-organization{

  width:100%;
  font-size:16px;font-weight:bold;color: #8083A3;padding:10px;background:#FFFFFF;border:1px solid #ECEEF5;
}
.strike-center-text{
  display: block;
  width: 100%;
  padding: 0;
  margin: 40px auto;
  color: #8083A3;
  width:192px;
  border: 0;
  border-bottom: 1px solid #ECECF2;
  text-align: center;
}
.strike-center-text span{
  display: inline-block;
  position: relative;
  margin-top: -15px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-weight:normal;
  margin: 0 auto;
  top: 10px;
  padding: 0px 10px;
}
.social-login{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.social-login .social-login-item{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #E8E7EE;
  border-radius: 4px; 
  padding-top: 13px;
  padding-bottom: 13px;
  align-items: center;
}

.social-login .social-login-item .social-logo{
  display: inline-block;
  text-align: center;
}

.social-login .social-login-item .social-text{

  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #8083A3
}
.remember-me{
  display:inline-block;width:39%;font-size:14px;
  font-weight: normal;
  color: #222831;
}

.forgot-password{
  display:inline-block;width:59%;text-align:right;
}

.forgot-password a{
  text-decoration:none;color:#222831;font-size:14px;
}
.forgot-password a:hover{
  color: #4DF189;font-size:14px;
}

.recover-header{
  text-align: center;padding-bottom:20px;
}
.partis{
  margin: 0 auto;
}

.partis ul{
  padding:0;
  display:inline-block;
  justify-content:space-between;
}

.partis ul li{

  width: 114px;
  height: 114px;
  display: inline-block;
  background: #ccc;
  font-size: 36px;
  text-align: center;
  color: #fff;
  border-radius: 20px;
  margin: 10px;
  vertical-align: middle;
}

.partis ul li a{
  cursor: pointer;
}

.partis ul li a img{

  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.partis ul li a span {

  margin-top: 30px;
  display: block;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 50px;
  position: relative;
  text-align: center;
  margin-top: -250px;
}
.btn-default {
  color: #8181A5 !important;
  background: #F6F6F6 !important;
  border: 1px solid #ECECF2 !important;
  border-radius: 8px;
}
.btn-signup {
  /* color: #8083A3;
  background-color: #fff;
  border-color: #ECEEF5;
  
  border-radius: 10px;
  box-sizing: border-box;
  width: 147px;
  height: 44px;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5; */
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #ECEEF5;
  text-align: center;
  box-sizing: border-box;
  height: 46px;
  display: inline-flex;
  padding: 8px;
  width: 147px;
  padding-right: 19px;
  gap: 11px;
  align-items: center;
  justify-content: center;
}

/* mouse over link */
.btn-signup a:hover {
  color: #4DF189;
}

/* selected link */
.btn-signup a:active {
  color: #4DF189;
}

.action a{
  font-size: 14px;
  margin-left: 5px;
}
.home-r-menu.action{
  display: flex;
  align-items: center;
}

.sidebar-menu{
  width: 83px;
  height: 100%;
  text-align: center;
  padding: 0px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}
.sidebar-logo{
  position: absolute;
  top: 10px;
  left: 20px;
}
.sidebar-menu-items{
  overflow-y: auto;
  margin-top: 120px;
  /* margin-left: 20px; */
  height: calc(100% - 120px);
  padding-bottom: 30px;
}
.sidebar-menu-items ul{
  padding:0;
}

.sidebar-menu-items li{
  list-style: none;
  padding: 10px;
  font-size: 22px;
  color: #8181A5;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
}

body.extended .page-sidebar .sidebar-menu .sidebar-menu-items li{
  text-align: left;
}

.sidebar-menu-items li.active{
  background: rgba(94,129,244,0.1);
}

.sidebar-menu-items li.active a{
  color:#5E81F4
}
.page-container.agent{
  padding-left: 88px;
}
.page-container{
  width:100%;
  height:100%;
  padding-right: 83px;

}
.page-header{
  padding: 22px 28px;border:none;margin: 0;height:87px;
}
.float-right{
  float:right;
}
.float-left{
  float:left;
}
.menu-divider{
  margin: 0 15px;
  border-left: 1px solid #ccc;
  height: 27px;
  display: inline-block;
  vertical-align: middle;
}
.bubble{
  background: #4DF189;
  color: #fff;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 12px;
}
.page-total-filter{
  background: #F8F9FC;padding: 20px
}
.page-filters{

}

.page-filters ul {
  padding: 0px;margin-top: 20px
}
.page-filters li{
  padding: 20px;border-top: 1px solid #EEEEEE;display: inline-block;width: 100%
}
.sidebar-menu-items a{
  color: #8083A3;
  cursor: pointer;
  font-size: 22px;
}
.sidebar-menu-items.main-m a{
  display: inline-flex;
}
td .profile-icon{
  background: var(--primary);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
td.action{
  text-align: right;
}
.table td{
  vertical-align: middle;
}
td.profile{
  width: 40px;
}
.page-content-stat{

}

.page-content-stat ul{
  padding: 0;
}

.page-content-stat li{list-style: none;
  width: 32%;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 12px;
  background: #fff;
  margin-right: 10px;
}
.page-content-stat li .stat-title{
  display: inline-block;
  width: 60%;
}
.page-content-stat li .stat-title .stat-title-bold{

  width: 100%;
  font-size: 16px;
  display: inherit;
  font-weight: bold;
}

.page-content-stat li .stat-title .stat-title-normal{
  font-size: 14px;color: #8083A3;
}

.page-content-stat li .stat-count{
  display: inline-block;
  float: right;
}

.page-content-stat li .stat-count .stat-count-bold{
  font-size: 18px;
  font-weight: bold;
}

.profile{
  padding: 20px 50px
}
.profile .profile-img-wrapper{
  width: 114px;
  display: block;
  background: #ccc;
  height: 114px;
  text-align: center;
  padding: 35px;
  border-radius: 20px;
  font-size: 30px;
  margin: 0 auto;
}
.profile .profile-name {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.page-tabs{

}

.page-tabs ul {
  padding: 0px;margin-top: 20px
}
.page-tabs li{
  padding: 10px 0;border-top: 1px solid #EEEEEE;display: inline-block;width: 100%
}
.page-tab-content .page-tabs li{
  border: none;
}
.page-tabs li .tab-item.active{
  background: #ECEEF5
}
.page-tabs li .tab-item{
  width: 100%;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 0 15px 15px;
}

.page-tabs li .tab-item .tab-icon{
  display: inline-block;
  width: 30px;
}
.page-tab-content .page-tabs li .tab-item .tab-icon {
  font-size: 30px;
  color: #8083A3;
  width: 50px;
}

.page-tabs li .tab-item .tab-title{

  display: inline-block;
  vertical-align: middle;
  font-size: 14px;font-weight: bold;
}
.page-content .page-tabs li .tab-item .tab-title{
  width: calc(100% - 200px);
}

.page-tab-content .page-tabs li .tab-item .tab-title{
  vertical-align: top;
}
.page-tabs li .tab-item .tab-title-desc{
  display: block;
  font-size: 12px;
  color: #8083A3;
  font-weight: normal;
}
.tab-header-title{
  font-size: 18px;font-weight: bold;margin-bottom: 15px;
}
.page-tab-content form{

}

.page-tab-content .form-group{
  margin-bottom: 20px;
}

.page-tab-content .form-group label{
  font-size: 14px;color: #8083A3;
}

.page-tab-content .form-group .form-control{
  font-size: 16px;font-weight: bold;color: #1A1C1D;
}
.page-tab-content .tab-actions{
  position: fixed;
  bottom: 0;
  width: calc(100% - 395px);
}
.btn{
  font-weight: bold;
  font-size: 14px;
  padding: 7px 15px;
}
.btn-header{
  padding: 10px 15px;
}
.btn.btn-primary{
  background: var(--primary);
  color: #fff;
  border:1px solid #FFFFFF;
  border-radius: 8px;
}

.btn-primary{
  background: var(--primary);
  color: #fff;
  border:1px solid #FFFFFF;
  border-radius: 8px;
}

.btn-primary-reversed{
  border: 2px solid #ECECF2;
  box-sizing: border-box;
  border-radius: 8px;
  background: white;
  color: var(--primary);
}

.btn-primary-reversed:hover{
  background: white;
  color: #fff;
  border: 2px solid #8181A5;
  border-radius: 8px;
  color: var(--primary);
}

.btn-primary-reversed:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  background: white;
  border: 2px solid #8181A5;
  color: var(--primary);
}

.page-tab-content .tab-actions .main-btn{
  float: left;
}

.page-tab-content .tab-actions .option-btn{
  float: right;
}
p{
  font-size: 14px;
}
.help_text{
  width: 80%;
  display: inline-block;
  font-size: 14px;
}

.btn-primary:hover{
  background: var(--primary);
  color: #fff;
  border:1px solid var(--primary);
  border-radius: 8px;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  background: var(--primary);
  border: 1px solid var(--primary)
}
ng-select2 .select2-container{
  width: 100% !important;
}
.call-log-filter .select2-container{
  z-index: 1070;
}
.select2-container--classic .select2-selection--multiple{
  border-bottom: 1px solid #ced4da
}
ng-select2.form-control{
  border: none;
  padding: 0;
}
.tag{

  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  border-radius: 8px;
  color: #5E81F4;
  font-size: 14px;
  font-width: bold;
  padding: 10px 16px;
  margin: 2px;
  display: flex;
  align-items: center;
}

.tag:empty{
  display: none;
}
.page-item{
  display: inline-block;
}

.ngx-pagination li.pagination-next,
.ngx-pagination li.pagination-previous{
  color: #8181A5 !important;
  background: #F5F5FA;
}

.ngx-pagination li.pagination-next{
  margin-left: 20px;
}
.ngx-pagination li.pagination-previous{
  margin-right: 20px;
}
.ngx-pagination li.pagination-next i,
.ngx-pagination li.pagination-previous i{
  font-size: 24px;
}
pagination-template{
  margin:  0 auto;
}
.ngx-pagination{
  padding: 0;
  margin: 0 auto !important;
  text-align: right;
}
.ngx-pagination li {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #8181A5 !important;
  background: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  vertical-align: middle;
}
.ngx-pagination li a{

  font-size: 14px;
  font-weight: bold;
  color: #8181A5 !important;
}
.paging{
  width: 100%;
  position: relative;
  text-align: center;
}

.ngx-pagination a, .ngx-pagination button {
  display: block;
}
.ngx-pagination .current{
  padding: 8px !important;
  color: #1C1D21 !important;
  border: 1px solid #ECECF2;
  font-weight: bolder;
}
.ngx-pagination a:hover{
  background:none !important;
}
.ngx-pagination a, .ngx-pagination button{
  padding: 0 !important;
}
.pagination {
  position: relative;
  padding-left: 0;
  list-style: none;
  width: 100%;
}
.ngx-pagination .disabled{
  padding: 6px !important;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before{
  content: "" !important;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after{
  content: "" !important;
}
.paging-size{
  height: 40px;
  background: #F5F5FA;
  border-radius: 6px;
  padding: 12px;
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
}

.paging-size label{
  text-transform: uppercase;
}
.paging-size select{
  background: transparent;
  border: none;
}



/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 30px;
  z-index: 999;
  min-width: 160px;
  left: -110px;
  text-align: left;
  width: auto;
  background: #FFFFFF;
  border: 1px solid #ECECF2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.dropdown-content.top-right{
  top: -30px;
  left: 0px;
}




/* Links inside the dropdown */
.dropdown-content a {
  color: #8181A5 !important;
  padding: 5px 10px;
  font-size: 12px;
  text-decoration: none;
  display: block;
}
.dropdown-content i{
  color: var(--primary);
}

.color-primary{
  color: var(--primary);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.upload-area{
  width: 100%;
  min-height: 300px;
  text-align: center;
  margin-top: 150px;
}
.fs-100{
  font-size: 100px;
}
.fs-11{
  font-size: 11px;
}
.record-area{
  width: 100%;
  text-align: center;
  padding: 100px;
  background: #5E81F4;
}

.user-profile-text{
  padding: 10px;
  background: var(--primary);
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  margin-right: 1px;
  text-align: center;
}
.user-profile-more{
  padding: 10px;
  background: #fff;
  border: 1px solid #ECEEF5;
  display: inline-block;
  border-radius: 5px;
  color: #8083A3;
  margin-right: 1px;
  text-align: center;
}
a.link{
  cursor: pointer;
  text-decoration: underline;
}
.overflow-auto{
  overflow: auto;
}
.page-container{
  padding-left:470px;
}

.page-sidebar.agent{
  width: 83px;
  border-right: 1px solid #ececec;
}
.page-sidebar{
  width: 470px;
  z-index: 10;
  left: 0px;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  background:#fff;
  transition: all 0.5s ease;
}
.sidebar-submenu{
  display: inline-block;
  vertical-align: top;
  width: 382px ;
  border-left:1px solid #F0F0F3;
  height:100%;
}
.btn-icon{
  background: #FFFFFF;
  color: #8181A5;
  padding: 4px;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
}

.btn-icon.have-bg{
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  border-radius: 5;
}

.ng-dropdown-panel .ng-dropdown-panel-items{
  max-height: 360px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  background: #F0F0F3;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgb(240,240,243);
}

.ng-select .ng-select-container .ng-value-container .ng-input>input{
  font-size: 14px;
}

ng-dropdown-panel .ng-dropdown-panel-items .ng-option-disabled:not(.ng-optgroup){
  background: #b5b5b5 !important;
}

.btn-icon.active{
  color: #5E81F4
}
.submenu-header{
  padding:22px 28px;border-bottom:1px solid #F0F0F3;header:87px;
}
.submenu-title{
  margin-left:20px;
  font-size:16px;font-weight:bold;color:#1C1D21
}

.submenu-title span{
  color:#8181A5;font-size:14px;display:block;
}

.submenu-items{
  padding: 20px 28px;
  height: calc(100% - 87px);
  overflow: auto;
}

.submenu-items ul{
  padding:0;
}

.submenu-items ul li{
  list-style: none;
  padding:18px;
  background:rgba(245, 245, 250, 0.4);
  color:#1C1D21;
  font-weight:bold;
  margin-bottom:6px;
  font-size: 14px;
  border-radius: 10px;
}
.submenu-items ul li.active{
  list-style: none;
  padding:17px;
  background:#F5F5FA
}

.submenu-items ul li .btn-icon{
  background:rgba(94, 129, 244, 0.1);
  color:var(--primary);
}
.page-header .btn-icon{
  background:rgba(129,129,165,0.1);
  color:#8181A5
}
.page-body{
  min-height: calc(100% - 87px);
  width: 100%;
  padding: 0 22px 28px;
  height: auto;
  position: relative;
}
.page-content{
  background:#fff;
  width:100%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  border-radius: 12px;
  padding: 20px 22px;
}

.page-content.zone{
  background-color: transparent;
  padding: 0px 0px 20px 0px;
}

.page-content.page-content-report{
  background: none;
  padding: 0;
}

.page-content-form{
  padding: 20px 100px;
}

.page-content-form.page-content-form-list{
  padding: 20px 22px;
}
.page-content-form .close-form{
  position: absolute;
  margin-left: -85px;
  background: #F5F5FA;
  color: #2E3A59;
}

.page-content-form.page-content-form-list .close-form{
  position: static;
  margin-left: 0;
}
.page-title{
  margin:0;
  color:#1C1D21;
  font-size:20px;
  font-weight:bold;
  white-space: nowrap;
}
.sidebar-user-profile{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: #ccc;
  color: #fff;
  padding: 7px;
  margin-left: 20px;
}
.table thead th{
  background: rgba(245, 245, 250, 0.4);
  border: none;
}

.table thead th.sorting{
  cursor: pointer;
}
.table thead th.sorting:before{
  font-family:'Font Awesome 5 Free';
  content: "\f1de";
  margin-right: 10px;
}

.table thead th.sorting.ASC:before{
  font-family:'Font Awesome 5 Free';
  content: "\f884";
  margin-right: 10px;
}

.table thead th.sorting.DESC:before{
  font-family:'Font Awesome 5 Free';
  content: "\f885";
  margin-right: 10px;
}
.table {
  border-collapse: separate;
}
.table { border-collapse: separate; border-spacing: 0 16px; }
.table td {
  border: 1px solid #ECECF2;
  padding: 10px;
  border-left: none;
  border-right: none;
}
.table tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-left: 1px solid #ECECF2;
}
.table tr td:last-child {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  border-right: 1px solid #ECECF2;
}
a{
  cursor: pointer;
}

.clickable{
  cursor: pointer;
}
.user-count{
  float: right;
  font-weight: normal;
}
.form-open .page-sidebar,
.form-open .page-header{
  filter: blur(3px);
}
.form-open .page-sidebar *,
.form-open .page-header *{
  pointer-events: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-open .newsletter-page-body{
  filter: blur(3px);
}

.modal-dialog-centered{
  max-height: 100vh;
}
.form-open.form-open-modal .page-sidebar,
.form-open.form-open-modal .page-container,
.form-open.form-open-modal .page-header{
  filter: blur(3px);
}
.form-open.form-open-modal .page-sidebar *,
.form-open.form-open-modal .page-container *,
.form-open.form-open-modal .page-header *{
  pointer-events: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



.page-form-title{
  font-size: 20px;
}

.form-control .select2-container--classic .select2-selection--multiple {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ced4da;
}
.form-control .select2-container--default .select2-selection--single{
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ced4da;
}
.form-control .select2-container .select2-selection--single{
  height: 35px;
}
.form-control .select2-container--classic .select2-selection--multiple .select2-selection__choice{
  background: #F5F5FA;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8181A5;
}
.form-control .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove{
  float: right;
  margin-left: 2px;
}
.form-control .select2-container{

}
.btn-submit{
  font-size: 16px;
  padding: 10px 25px;
}

.actions .btn{
  min-width: 170px;
  padding: 12px;
  font-size: 16px;
}
.toast-container .ngx-toastr{
  background: #fff;
  border-radius: 6px !important;
  box-shadow: 0px 2px 26px rgba(82, 215, 0, 0.1) !important;
  padding: 10px !important;
}
.toast-title{
  color: #333333;
  font-size: 16px;
}
.toast-message{
  color: #4D4D4D;
  font-size: 14px;
}
.toast-success{
  border: 1px solid #6FCF97;
}

.toast{

}
.notify{

}

.notify .notify-icon{
  display: inline-block;
  width: 45px;
  padding: 5px;
  color: #ccc;
  font-size: 25px;
}
.notify .notify-message{
  display: inline-block;
  vertical-align: top;
  width: 230px;
}
.notify .notify-close{
  position: absolute;
  right: 15px;
  top: 10px;
  color: #828282;
  font-size: 16px;
}

.toast-success .notify .notify-icon{
  color: #6FCF97;
}

.toast-warning{
  border: 1px solid #F2C94C;
}
.toast-warning .notify .notify-icon{
  color: #F2C94C;
}

.toast-error{
  border: 1px solid #EB5757;
}
.toast-error .notify .notify-icon{
  color: #EB5757;
}

.toast-plivo .notify .notify-icon{
  color: #000 !important;
}
.toast-plivo{
  background: #FF808B !important;
  box-shadow: 0px 8px 6px -3px rgba(0, 0, 0, 0.25);
  border-radius: 10px !important;
  border: none;
  width: 500px !important;
}

.toast-plivo .notify .notify-message{
  width: auto !important;
}
.toast-plivo .notify .notify-close{
  color: #000;
}
.toast-plivo .notify .notify-icon{
  /* color: #FF808B; */
}

.form-group.error .form-control{
  border-color: #FF808B;
}

.form-group.success .form-control{
  color: #7CE7AC;
}
.form-group.error:after{
  font-family: "Font Awesome 5 Free";
  content: "\f06a";
  font-weight:900;
  color: #FF808B;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  float: right;
  margin-top: -30px;
}

.form-group.success:after{
  font-family: "Font Awesome 5 Free";
  content:"\f00c";
  font-weight:900;
  color: #7CE7AC;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  float: right;
  margin-top: -30px;
}
.form-group.required label:after{
  color: #FA5959;
  content: "*";
  margin-left: 5px;
}
.col-form-label{
  padding-top: 10px;
}
#bee-plugin-container iframe{
  min-width: 500px !important;
  width:100% !important;
  min-height: 500px;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  min-height: 400px;
  height: auto;
}

.wizzard-header{

  background: rgba(245, 245, 250, 0.4);
  padding: 21px;
}
.wizzard-header ul{
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 550px;
}

.wizzard-header ul li{
  display: inline-block;
  margin: 0;
  padding: 0;
}

.wizzard-content{
  width: 450px;
  margin: 0 auto;
}

.wizzard-content .wizzard-step{

}

.wizzard-content .wizzard-step .wizzard-title{
  text-align: center;
  font-size: 20px;
  color: #1C1D21;
  font-weight: bold;
}


.wizzard-content .wizzard-step .wizzard-title span{
  display: inline-block;
  color: #8181A5;
  font-size: 14px;
  width: 100%;
  font-weight: normal;
}
.wizzard-content .wizzard-step .wizzard-text{
  color: #8181A5;
  text-align: center;
}
.wizzard-actions{
  width: 550px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}

.wizzard-actions .btn{

  padding: 14px;
  min-width: 170px;
  font-size: 16px;
  font-weight: bold;
}
.wizzard-header ul li.done i,
.wizzard-header ul li.current i{
  color: var(--primary);
}
.wizzard-step .small-input{
  width: 300px;
  margin:  0 auto;
}
.wizzard-step .phone_confirmation{
  padding:0;
}

.wizzard-step .phone_confirmation li{
  font-size: 14px;
  font-weight: bold;
  color: #8181A5;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.wizzard-step .phone_confirmation li i{
  color:#5E81F4;
  float: right;
}
.btn-rent{
  border-radius: 12px;
  color: #5E81F4;
  background: rgba(94, 129, 244, 0.1);
  border: 1px solid #5E81F4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 45px;
}
.btn-active,btn-inactive{
  background: var(--primary);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  padding: 0px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.btn-inactive{
  background-color: var(--primary_opacity);
  color: var(--primary);
}

.btn-inactive:not(:disabled):hover{
  color: var(--primary);
  background-color: var(--primary_opacity);
  border: 1px solid var(--primary)
}

.btn-inactive:not(:disabled):not(.disabled):active, .btn-inactive:not(:disabled):not(.disabled).active, .show > .btn-inactive.dropdown-toggle{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--primary);
  background-color: var(--primary_opacity);
  border: 1px solid var(--primary)
}

.search-form{
  position: absolute;
  right: 22px;
  margin-top: 30px;
  z-index: 999;
  background: #fff;
  border: 1px solid #ECECF2;
  border-radius: 8px;
}
.search-form form{
  padding: 30px 15px;
}
.search-action{
  padding: 15px;
  text-align: center;
  color: #5E81F4;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
}
.submenu-items ul li a{
  color: #1C1D21;
}
.table>caption+thead>tr:first-child>td, .table>caption+thead>tr:first-child>th, .table>colgroup+thead>tr:first-child>td, .table>colgroup+thead>tr:first-child>th, .table>thead:first-child>tr:first-child>td, .table>thead:first-child>tr:first-child>th{
  border:none;
}
tr td a{
  color:#212529;
}
.contact-upload-area{
  text-align: center;
}
.contact-upload-area input{
  margin:0 auto;
}
app-table-header{
  display: contents;
}
.select2-dropdown.pagination_select{
  width: 50px;
  border: 1px solid #ECECF2;
  margin-top: -16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #1C1D21;
}
.pagination_select .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(245, 245, 250, 0.4);
  color: #5E81F4;
}
.pagination_select .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #5E81F4;
  color: #fff;
}

.paging-size .select2-container{
  width: 50px !important;
  margin-top: -2px;
}
.select2-container--default .select2-selection--single.pagination_select{
  border: none;
  background: none;
}
.pagination_select .select2-search--dropdown{
  display: none;
}
.dropdown-content.show{
  display: block;
}
.btn-grey{
  background: #F6F6F6;
  border: 1px solid #ECEEF5;
}

.text-gray{
  color: #8181A5;
}

.campaign-import-list .dropdown-content{
  min-width: 185px;
  left: -150px;
}
.campaign-import-list .dropdown-content a{
  font-weight: bold;
  font-size: 14px;
  color: #1C1D21 !important;
}

.dropdown-menu-mapping.dropdown-menu{
  width: 500px;
  padding: 10px;
  height: 500px;
  overflow: auto;
}

.dropdown-menu-mapping  ul{
  padding: 0;
}
.dropdown-menu-mapping .selectedColumn{
  border-bottom: 1px solid #E9ECEE;
  margin-bottom: 20px;
}

.dropdown-menu-mapping .selectedColumn .field-select{
  display: inline-block;
  margin-right: 5px;
  color: var(--primary);
  padding: 0;
  height: 25px;
  font-size: 14px;
  text-align: center;
  width: 100px;
  border-color: var(--primary);
  background: #fff;
}

.dropdown-menu-mapping .selectedColumn select.form-control{
  max-width: 120px;
}
.dropdown-menu-mapping  li{
  list-style: none;
  display: inline-block;
  width: 100%;
}


.dropdown-menu-mapping  li .field-icon{
  padding: 15px 5px;
  display: inline-block;
  float: left;
}

.dropdown-menu-mapping  li .field-icon a{
  background: linear-gradient(
    0deg
    , rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  height: 38px;
  width: 38px;
  padding-top: 13px;
}

.dropdown-menu-mapping li .field-icon a span{

  color: var(--primary) !important;
  font-size: 18px;
  display: inline-block;
}
.dropdown-menu-mapping li .field-desc{
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  padding-top: 5px;
  width: calc(100% - 50px);
}

.dropdown-menu-mapping li .field-desc span{
  /* font-weight: 300; */
}

.dropdown-menu-mapping li .field-desc span.desc{
  display: block;width: 100%;
}
/* display: block;width: 100%; */

.dropdown-menu-mapping li .field-desc span.info{
  font-weight: 500;
}

.dropdown-menu-mapping li .field-desc i.matched-column{
  font-style: normal;
  color: #5E81F4;
  display: inline-flex;
  padding: 0 5px;
}

.dropdown-menu-mapping li .field-desc i.matched-percent{
  font-style: normal;
  color: #F4BE5E;
}
.text-primary{
  color: var(--primary) !important;
}
ngb-timepicker fieldset{
  display: inline;
  width: 110px;
  margin: 0 auto;
}
.switch.checked{
  background: var(--primary);
}
.switch.checked small{
  right: 4px;
}
.switch.switch-small{
  height: 20px;
  border-radius: 12px;
  min-width: 38px;
}
.switch.switch-small small{
  width: 15px;
  height: 15px;
  top: 2px;
}
body.extended .page-sidebar{
  width: 242px !important;
  transition: all 0.5s ease;
}
body.extended .page-sidebar  .sidebar-menu{
  width: 100%;
}
.page-sidebar  .sidebar-menu-items li a span{
  display: none;
}

body.extended .page-sidebar  .sidebar-menu-items li a span{
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
}

body.extended .page-sidebar  .sidebar-submenu{
  display: none;
}

body.extended .page-container{
  padding-left: 242px;
}
body.extended-right .page-container{
  /* padding-right: calc(576px + 83px); */
}

.search.open .search__dropdown, .search.open .search__backdrop {
  display: block;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 33, 0.35);
  z-index: 98;
}

.call-status-modal .modal-content,
.call-status-modal-force .modal-content{
  border-radius: 12px !important;
  padding: 30px !important;
}
.agent-pause-modal .modal-content{
  border-radius: 12px !important;
  padding: 30px !important;
  border: 3px solid #F4BE5E !important;
}
.agent-success-modal .modal-content{
  border-radius: 12px !important;
  padding: 30px !important;
  border: 3px solid #4DF189 !important;
}
.call-status-modal-force .modal-content{
  border: 3px solid #dc3545 !important;
}
.call-status-modal .modal-footer,
.call-status-modal-force .modal-footer{
  border: none;
  display: block;
}


.agent-pause-modal .modal-footer,
.agent-success-modal .modal-footer{
  border: none;
  justify-content: center;
}
.agent-pause-modal .status-title,
.agent-success-modal .status-title,
.call-status-modal .status-title,
.call-status-modal-force .status-title
{
  font-size: 24px;font-weight: bold;
}
.call-status-modal .status-phone,.call-status-modal-force .status-phone{
  font-size: 12px
}
.agent-pause-modal .form-group label,
.call-status-modal .form-group label,
.call-status-modal-force .form-group label{
  font-size: 12px;color: #949CA9;
}

.agent-pause-modal .form-group .form-control,
.call-status-modal .form-group .form-control,
.call-status-modal-force .form-group .form-control{
  font-size: 16px;color: #222831;
  padding: 0;
  font-weight: normal;
}
.agent-pause-modal .modal-footer .btn,
.agent-success-modal .modal-footer .btn,
.call-status-modal .modal-footer .btn,
.call-status-modal-force .modal-footer .btn{
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
}
.keypad-popover{
  width: 275px;
  background: #fff;
  border-radius: 12px;
  margin-left: -70px;
  padding: 16px;
}

.keypad-popover-global{
  width: 335px;
  background: #fff;
  border-radius: 12px;
  margin-left: -70px;
  padding: 0px;
  max-width: 335px;
}

.keypad-popover-global .arrow{ 
  margin-left: 65px !important;
}


.keypad-popover .arrow{
  margin-left: 65px !important;
}


.flag-popover{
  width: 244px;
  border: 1px solid #ECECF2;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: -100px;
  padding: 0px;
}

.flag-popover .arrow{
  margin-left: 95px !important;
}

.flag-popover .popover-body{
  padding: 0;
}

.card-container {
  grid-column: center-start/-1;
  overflow: auto;
  display: flex;
  padding-bottom: 1em;
}
.card-container .card {
  height: 158px;
  width: 158px;
  min-width: 158px;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 8px;
  margin-right: 30px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
}
.card-container .card.active{
  box-sizing: border-box;
  box-shadow: 0px 4px 11px 1px var(--primary);

}

.card-container .card .card-title{
  font-size: 12px;
  font-weight: bold;
  color: var(--primary);
}

.card-container .card .card-title a{
  float: right;
  color: #979797;
  font-size: 12px;
  font-weight: bold;
}

.card-container .card.new-card .card-title a{
  color: var(--primary);
}

.card-container .card.active .card-title a{
  color: var(--primary);
}

.card-container .card .card-type{
  text-align: center;
  padding: 10px;
}

.card-container .card .card-type i{
  color: var(--primary);
  font-size: 45px;
}

.card-container .card .card-number{
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.card-container .card .card-delete{
  text-align: center;
  margin-top: 5px;
}

.card-container .card .card-delete a.btn{

  padding: 0;
  width: 38px;
  text-align: center;
  background: var(--primary);
  border-radius: 2px;
  color: #fff;
}
.card-container .card .card-delete i{
  font-size: 11px;
}
.billing_modal .modal-header{
  border-bottom: none;
  color: #5E81F4;
  font-size: 18px;
  font-weight: bold;
}

.billing_modal .modal-footer{
  border: none;
  display: inline-block;
}
.billing_modal .modal-content{
  width: 660px;
  padding: 30px;
}

.contact-form-container-collapse{
  padding: 30px;
  border: 1px solid #ECECF2;
  border-radius: 12px;
}

.contact-form-container-collapse .collapse-title{
  font-size: 18px;
}

.contact-form-container-collapse .collapse-body{
  padding: 20px 0;
}

.btn-action{
  width: 175px;
}
.btn-close{
  width: 135px;
}
/* .progress-bar{
  background-color: var(--primary) !important;
} */

.table td{
  font-size: 14px;
}

.call-log-popover{
  width: 360px;
  background: #fff;
  border-radius: 8px;
  margin-left: -150px;
  border: 1px solid #ECECF2;
  max-width: 360px;
}

.call-log-popover .arrow{
  display: none;
}
.call-log-popover .popover-body{
  padding: 0;
}
.select2-container{
  z-index: 1050;
}
.select2-container--classic.select2-container--open .select2-dropdown{
  border-color: #fff;
}
.filter-block-content .form-control .select2-container--classic .select2-selection--multiple{
  border: none;
}
.call-log-filter-bottom{

  background: linear-gradient(
    0deg
    , rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  border: 1px solid #ECECF2;
  padding: 15px;
}

.call-log-filter-bottom a{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #5E81F4 !important;
  display: block;
}
ngb-datepicker.dropdown-menu{
  z-index: 1100;
}

/* The Modal (background) */
.loading-container {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1600;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(255, 255, 255, 0.32);
  backdrop-filter: blur(15px);
}

/* Modal Content */
.loading-content {
  margin: auto;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 183px;
  height: 183px;
  background: #FFFFFF;
  border: 2px solid #5E81F4;
  box-sizing: border-box;
  box-shadow: 0px 8px 14px rgb(0 0 0 / 20%);
  border-radius: 12px;
}

.loading-content i{
  font-size: 49px;
  color: var(--primary);
  text-align: center;
  display: block;
  padding-top: 40px;
}

.contact-profile{

}

.contact-profile .form-group.focus{
  background: rgba(94, 129, 244, 0.1);
  border-radius: 8px;
  padding: 11px;
}
.contact-profile .form-group label{
  font-size: 14px;
  color: #949CA9;
}
.newsletter-select .ng-option-disabled.ng-optgroup {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.contact-profile .form-group.focus .form-control{
  color: #222831;
  border: none;
  background: rgba(94, 129, 244, 0.01);
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
}


.contact-profile .form-group.error.focus{
  background: rgba(255, 128, 139, 0.1);
}
.contact-profile .form-group.error.focus .form-control{
  background: rgba(255, 128, 139, 0.01);
}

.contact-profile .form-group.success.focus,
.contact-profile .form-group.submitting,
.contact-profile .submitting .form-group{
  background: rgba(77, 241, 137, 0.2);
}
.contact-profile .form-group.success.focus .form-control,
.contact-profile .form-group.submitting .form-control,
.contact-profile .submitting .form-group .form-control{
  background: rgba(77, 241, 137, 0.02);
}
.contact-profile .form-group.view a{
  display: none
}
.contact-profile .form-group a.icon-edit:before{
  content: "\f044";
}
.contact-profile .form-group.success.focus a.icon-edit:before,
.contact-profile .form-group.submitting a.icon-edit:before,
.contact-profile .submitting .form-group a.icon-edit:before{
  content: "\f058";
  color: #28C345;
}

.contact-profile .form-group.error.focus a.icon-edit:before{
  content: "\f05a";
  color: #FA5959;
}

.contact-profile .form-control .select2-container--default .select2-selection--single{
  border: none;
  background: none;
}
.contact-profile .form-control{
  border: none;
}

.form-email .ql-toolbar.ql-snow{

  bottom: 0;
  position: absolute;
  width: 96%;
  border: none;
  background: #F5F5FA;
  z-index: 10;
  border-radius: 12px;
}
.form-email .ql-toolbar.ql-snow + .ql-container.ql-snow{
  border: none;
}
.form-email .ql-editor{
  height: 380px;
  background: #F5F5FA;
  border: none;
  border-radius: 12px;
}
.form-email{
  padding: 20px;
}
.form-email .form-group{
  border: 1px solid #ECECF2;
  height: 40px;
}

.form-email .form-control{
  border: none;
}

.ql-editor.ql-blank::before{
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #979797;
}

.form-email .form-group label.col-form-label{
  font-size: 11px;
  font-weight: bold;
  color: #949CA9;
  border-right: 1px solid #ECECF2;
  padding-left: 30px;
}
.btn-large{
  padding: 15px 25px;
  font-size: 16px;
  border-radius: 10px;
}

.agent-waiting-modal .modal-dialog{
  width: 792px;
  max-width: 792px;

}
.agent-waiting-modal .modal-content{
  border-radius: 12px;
}
.agent-waiting-modal .modal-body{
  padding: 0;
}

.waiting-header .dial-right-action{
  float: none !important;
}
.agent-waiting-modal .modal-body .waiting-header{
  padding: 30px;
  text-align: center;
  background: var(--primary_opacity);
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
}

.agent-waiting-modal .modal-body .waiting-content{
  padding: 50px 70px;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  border-radius: 0px 0px 12px 12px;
}
.agent-waiting-modal .modal-body .waiting-header .call-text{

  font-size: 16px;
  color: #5E81F4;
  margin-bottom: 10px;
}

.agent-waiting-modal .modal-body .waiting-header .call-icon{

}
.start-modal h3{
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.start-modal p.waiting-desc{
  font-size: 18px;
  text-align: center;
}

.start-modal p{
  font-size: 14px;
  text-align: left;
}
.start-modal .waiting-body{
  border-top: 2px solid #ECECF2;
  padding: 20px 0;
}

.start-modal .waiting-block{

  border: 2px solid #ECECF2;
  box-sizing: border-box;
  border-radius: 18px;
  height: 112px;
  margin-bottom: 20px;
  padding: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #464A5F;
  display: block;
}

.start-modal .waiting-block:hover{
  border: 2px solid var(--primary);
}


.start-modal .waiting-block i{

  color: var(--primary);
  margin-right: 50px;
  font-size: 25px;
  vertical-align: middle;
}

.tutorial-modal{
  top: 35%;
}

.tutorial-modal .modal-content{
  border-radius: 12px;
}

.tutorial-modal .modal-body{
  padding: 30px;
}

.tutorial-modal .modal-body h3{
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.tutorial-modal .modal-body p{
  font-size: 12px;
  font-weight: normal;
  text-align: center;
}

.tutorial-modal .modal-footer{
  justify-content: center;
  border: none;
}



.start-modal{
  top: 0;
}

.start-modal .modal-footer{
  border: none;
}

.start-modal .modal-dialog{

  max-width: 100%;
  width: calc((100% - 550px)*7/12);
  position: absolute;
  right: 20px;
  height: 100%;
}

.vote-location-modal .modal-dialog{
  max-width: 100%;
  width: calc(100% - 40px);
  position: absolute;
  right: 20px;
  left: 20px;
  height: 100%;
}

.start-modal .modal-body{
  padding: 15px;
  overflow: auto;
}
.start-modal .modal-content{
  height: calc(100% - 40px);
  border-radius: 12px;
  background: #f5f5fa;
}

.start-modal .start-header{

}

.start-header .btn{
  width: 100%;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
}

.start-header .btn i{
  font-size: 26px;
}
.start-modal .start-body{
  background: #fff;
  display: block;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 20px;
  border-radius: 12px;
  border: 1px solid var(--primary);
  padding: 30px;
  text-align: left;
  padding-bottom: 0;
}
.start-modal .start-body .page-content-title{
  font-size: 24px;
  font-weight: 700;
  float: left;
  color: #222831;
  width: 100%;
}
.start-modal .start-body .page-content-title span{
  margin-left: 0 !important;
}

.start-body .btn-danger{
  position: absolute;
  bottom: 10px;
  right: 30px;
}
.keypad-content{
  padding: 20px;
}
.keypad-content .keypad-inp{
  background: #ECECF2;
  padding: 10px;
  text-align: center;
  border-radius: 12px;
}


.keypad-content .keypad-inp .call-text{    
  font-size: 25px;
  font-weight: bold;
}

.keypad-popover-global .keypad-digits ul li{
  width: 75px;
  height: 58px;
}

.keypad-content .keypad-inp .call-time{    
  font-size: 15px;
}
.keypad-content .keypad-inp .call-type{    
  font-size: 15px;
  font-weight: 500;
  color: #8181A5;
}
.keypad-content .keypad-digits{

}

.keypad-bottom{
  background: #F5F5FA;
  padding: 15px;
}
.popover-body{
  padding: 0;
}

.keypad-digits ul{
  padding: 0;
}
.keypad-digits ul li{
  width: 58px;
  height: 58px;
  margin: 5px 8px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #1C1D21;
  display: inline-block;
  list-style: none;
}

.keypad-digits ul li a{
  width: 58px;
  height: 58px;
  border-radius: 50%;
  font-size: 30px;
  color: #000000;
}

.pop-header{

  padding: 16px;
}
.pop-body{
  padding: 16px;
}
.pop-bottom{
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  border: 2px solid #ECECF2;
  padding: 13px;
  text-align: center;
  color: #5E81F4;
}
.pop-title{
  color: #8181A5;
  font-size: 16px;
  font-weight: bold;
}
.flag-body{

}

.flag-body .flag-content{
  display: inline-block;
  width: 100%;
  color: #1C1D21;
  font-size: 11px;
  font-weight: bold;
  padding: 14px;
  border: 1px solid #EEEEEE;
  margin-bottom: 10px;
  border-radius: 3px;

}

.flag-body .flag-content i{
  float: right;
  color: #8181A5;
}

.flag-body .flag-content.active{
  border: 1px solid #5E81F4;
}

.form-open .calling-contact{
  filter: blur(3px);
}

.form-open .calling-contact *{
  pointer-events: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.calling-header .dial-right-action a.btn-icon i{
  font-size: 16px !important;
  color: #fff;
  display: block;
  margin-top: 7px;
}
.start-body.game-waiting{
  padding: 0;
}
.game-waiting .page-content-title{
  padding: 40px 60px;
}
.game-waiting .game-board{
  padding: 0;
  display: block;
}
.email-template-modal .modal-dialog{
  width: 1200px;
  max-width: 1200px;
}
.campaignModalStyle{

}
.campaignModalStyle .modal-dialog{

  width: calc(100% - 510px);
  max-width: calc(100% - 470px);
  margin-left: 490px;
  margin-top: 40px;
}
.campaignModalStyle .modal-header{
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #1C1D21;
  border: none;
  padding-top: 15px;
}

.campaignModalStyle .modal-header .btn-icon{
  margin-left: 5px;
}

.campaignModalStyle .modal-content{
  border-radius: 12px;
}
.campaignModalStyle .modal-body{
  padding: 0;
}
.audioUploadStyle .modal-content{
  border-radius: 12px;
  font-size: 24px;
  font-weight: 300;
}
.audioUploadStyle .modal-header{
    border: none;
}
.audioUploadStyle .modal-footer{

}

.campaignModalStyle .modal-body-content{

  margin: 20px 60px;
  background: rgba(245, 245, 250, 0.4);
  border: 2px solid #ECECF2;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 60px;
}
.campaignModalStyle .modal-footer{
  border: none;
  margin-right: 45px;
  padding-bottom: 20px;
}

.campaignModalStyle .modal-footer .btn{

}
.modal-body-content .content-title{
  color: #1C1D21;
  font-size: 20px;
  font-weight: 700;
}

.modal-body-content .content-title span{
  display: block;
  font-size: 14px;
  font-weight: normal;
}
app-script-editor{
  display: block;
}
.ql-container{
  min-height: 300px;
}

.fancy-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.fancy-scrollbar.thin::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.fancy-scrollbar::-webkit-scrollbar-track {
  background: rgba(245, 245, 250, 0.4);
  overflow: auto;
}

.fancy-scrollbar::-webkit-scrollbar-thumb {
  background: #979797;
  overflow: auto;
  border-radius: 100px;
}

.status-success{
  background: rgba(77, 241, 137, 0.2) !important
}

.status-error{
  background: rgba(250, 89, 89, 0.3) ! important;
}

.status-success .ng-select-container{
  background: rgba(77, 241, 137, 0.2) !important
}

.status-error .ng-select-container{
  background: rgba(250, 89, 89, 0.3) ! important;
}

.fs-10{
  font-size: 10px !important;
}

.input-container-right{
  border-radius: 12px;
  padding: 8px;
  padding-right:45px;
  padding-left: 16px;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

.input-container-left{
  border-radius: 12px;
  padding: 8px;
  padding-right:16px;
  padding-left: 45px;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

.input-container-right.contact{
  padding-right: 35px !important;
}

.input-container-left.contact{
  padding-left: 35px !important;
}

.input-container-right.small{
  padding-right: 25px !important;
  padding-left:8px !important;
}

.input-container-left.small{
  padding-left: 25px !important;
  padding-right:8px !important;
}

.input-feedback-position-container{
  position: relative;
  right: 34px;
  top: 6px;
}

/* .input-feedback-position-container.small{
  right: 22px !important;
}

.input-feedback-position-container.contact{
  right: 28px !important;
}
 */
.feedback-animation{
  font-size: 0px;
  -webkit-transition: font-size 1s ease;
  -moz-transition: font-size 1s ease;
  -o-transition: font-size 1s ease;
  transition: font-size 1s ease;
}

.form-control{
  background: content-box !important;
}


.ng-option:not(.ng-option-disabled) .select-option{
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  background: none !important;
  color: #1C1D21;
  flex: none;
  flex-grow: 0;
  margin: 0px 10px;
}

.ng-option.ng-option-disabled .select-option{
  color: #8181A5;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  background: none !important;
  margin: 0px 10px;
}

.select-value{
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  background: none !important;
  color: #1C1D21;
  flex: none;
  flex-grow: 0;
}

.form-select .ng-select-container{
  border: 1px solid #ced4da !important;
  border-top: none !important; 
  border-left: none !important; 
  border-right: none !important; 
}

ng-dropdown-panel.form-select{
  border: 1px solid #8181A5;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.4);
    border-radius: 6px;
    border-top-color: #8181A5 !important;
}

.form-select.ng-select-disabled{
  background: rgba(129,129,165, 0.12);
  
}

.ng-value-container .select-value{
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  background: none !important;
  color: #1C1D21;
  flex: none;
  flex-grow: 0;
}

.ng-value.ng-value-disabled .select-value{
  color: #8181A5;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  background: none !important;
}

.script_message{
  background: #FFFFFF;
  border: 1px solid #ECECF2;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 18px;
}
.script_message .text-editor{
  background: #F5F5FA;
  border-radius: 12px;
  padding: 0px;
}

.script_message .text-editor .text-editor-bar{
  padding: 0;
}

textarea.form-control{
  border: 1px solid #ced4da;
}

.tooltip-hangup .tooltip-inner {
  background-color: #C05D3F;
  font-size: 125%;
}
.tooltip-hangup .arrow::before {
  border-top-color: #C05D3F;
  border-bottom-color: #C05D3F;
}

.tooltip-mute .tooltip-inner {
  background-color: #ECD089;
  font-size: 125%;
}
.tooltip-mute .arrow::before {
  border-top-color: #ECD089;
  border-bottom-color: #ECD089;
}
.tooltip-mute .tooltip-inner {
  background-color: #ECD089;
  font-size: 125%;
}
.tooltip-mute .arrow::before {
  border-top-color: #ECD089; 
  border-bottom-color: #ECD089;
}

.tooltip-pause .tooltip-inner ,
.tooltip-recall .tooltip-inner {
  background-color: #F4BE5E;
  font-size: 125%;
}
.tooltip-pause .arrow::before,
.tooltip-recall .arrow::before {
  border-top-color: #F4BE5E;
  border-bottom-color: #F4BE5E;
}

.tooltip-voicemail .tooltip-inner ,
.tooltip-dispo .tooltip-inner {
  background-color: #5E81F4;
  font-size: 125%;
}
.tooltip-voicemail .arrow::before,
.tooltip-dispo .arrow::before {
  border-top-color: #5E81F4;
  border-bottom-color: #5E81F4;
}

.tooltip-keypad .tooltip-inner {
  background-color: #28a745;
  font-size: 125%;
}
.tooltip-keypad .arrow::before {
  border-top-color: #28a745; 
  border-bottom-color: #28a745;
}

.tooltip-flag .tooltip-inner {
  background-color: #8181A5;
  font-size: 125%;
}
.tooltip-flag .arrow::before {
  border-top-color: #8181A5; 
  border-bottom-color: #8181A5;
}

.form-select .ng-select-container .ng-value-container .ng-placeholder{
  font-weight: 700;
  font-size: 14px;
  color: #979797;
}

.tag-icon{
  margin-right: 3px;
  font-size: 18px;
}

.dial-code-select.ng-dropdown-panel{
  z-index: 10000 !important;
  min-width: 250px !important;
}

.dial-code-select .ng-select-container{
  border: none !important
}

/* Make the select inherit de colors */
.dial-code-select .ng-select-container{
  background: content-box !important;
}

apx-chart{
  margin: 0 auto;
  /* display: table; */
}


.profile-image{
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}

.campaign-filter .form-control .select2-container--default .select2-selection--single{
  border-bottom: none;
}

.campaign-filter .select2-container .select2-selection--single .select2-selection__rendered{
  margin-top: 2px;
}
/* .ng-select .ng-select-container{
  border: none;
    padding: 0;
    margin-top: -6px;
} */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: .9em;
  margin-bottom: 5px;
  color: #333;
  background-color: #F5F5FA;
  border-radius: 2px;
  margin-right: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
  float: right;
  border: none;
}
agm-map {
  height: 500px;
}

.agm-info-window-content{
  font-size: 15px;
}
.btn-img{
  background: #8181A5 !important;
}


.view-invite-modal{
}
.view-invite-modal .modal-dialog{
  width: 100%;
  max-width: 100%;
}

.view-invite-modal .modal-content{

}
.view-invite-modal .modal-header{

}

.view-invite-modal .modal-header .modal-title{
  text-align: center;
  width: 100%;
  padding: 50px;
  font-weight: 700;
  font-size: 40px;
}

.view-invite-modal .modal-header .modal-title span{
  font-size: 18px;
  width: 100%;
  display: block;
  font-weight: normal;
}
.view-invite-modal .modal-body{

}
.view-invite-modal .modal-footer{

}

.view-invite-modal .modal-footer .invite-footer{
  width: 550px;
  margin: 0 auto;
}

.view-invite-modal .modal-footer .invite-footer .btn{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}




.view-config-modal{
}
.view-config-modal .modal-dialog{
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: #FAFDFD;
  height: auto;
  min-height: 100%;
}

.view-config-modal .modal-content{
  border: none;
}
.view-config-modal .modal-header{

}

.view-config-modal .modal-header .modal-title{
  text-align: center;
  width: 100%;
  padding: 50px;
  font-weight: 700;
  font-size: 40px;
}

.view-config-modal .modal-header .modal-title span{
  font-size: 18px;
  width: 100%;
  display: block;
  font-weight: normal;
}
.view-config-modal .modal-body{

}
.view-config-modal .modal-footer{

}

.view-config-modal .modal-footer .invite-footer{
  width: 550px;
  margin: 0 auto;
}

.view-config-modal .modal-footer .invite-footer .btn{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}


.expand-game-modal{
  top: 0;
}

.expand-game-modal .modal-footer{
  border: none;
}

.expand-game-modal .modal-dialog{

  max-width: 100%;
  width: 97%;
  position: absolute;
  right: 20px;
  height: 100%;
}
.expand-game-modal .modal-body{
  padding: 15px;
}
.expand-game-modal .modal-content{
  height: calc(100% - 40px);
  border-radius: 12px;
  background: #fff;
}
.expand-game-modal .btn{
  width: 100%;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
}

.expand-game-modal .btn i{
  font-size: 26px;
}
.expand-game-modal .start-body{
  background: #fff;
  display: block;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 20px;
  border-radius: 12px;
  border: 1px solid var(--primary);
  padding: 30px;
  text-align: left;
  padding-bottom: 0;
}
.expand-game-modal .start-body .page-content-title{
  font-size: 24px;
  font-weight: 700;
  float: left;
  color: #222831;
  width: 100%;
}
.expand-game-modal .start-body .page-content-title span{
  margin-left: 0 !important;
}

.expand-game-modal .page-content-title span{
  margin-left: 0 !important;
  font-size: 18px !important;
  color: #222831;
}
.expand-game-modal .page-content-title{

font-size: 24px !important;
font-weight: bold;
color: #222831 !important;
}

.carousel-arrow{
  width: 175px !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  margin-top: 0 !important;
  z-index: 10 !important;
  background: transparent !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  background: linear-gradient( 
    90deg, rgba(255, 255, 255, 0.21) 0%, #FFFFFF 100%) !important;
  backdrop-filter: blur(1px);
  border: none;
}
.carousel-arrow-disabled{
  display: none;
}
.carousel-arrow::after {
  font-family: 'Font Awesome 5 Free';
  margin-left: 50px;
  top: 50%;
  font-weight: 900;
  display: block;
  color: var(--primary);
  font-size: 40px;
  position: absolute;
  background: #fff;
  text-align: center;
}
.carousel-arrow-prev::after {
  content: "\f100";
}

.carousel-arrow-next::after {
  content: "\f101";
}
.carousel-arrow-prev{
  left: 0px !important;
}
.carousel-arrow-next{
  right: 0 !important;
}
.toast-top-center{
  top: 40% !important;
  left: 40% !important;
}
.toast-bottom-center{
  bottom: 10% !important;
  left: 40% !important;
}
.pac-container{
  z-index: 1100;
}
.show_config_bar .page-sidebar{
  top: 40px !important;
  height: calc(100% - 40px) !important;
}

.show_config_bar .page-container{
  padding-top: 40px;
}
carousel{
  z-index: 1000 !important;
}

.subscribe-tooltip .tooltip-inner{
  background: #8181A5;
  padding: 5px 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
}
.subscribe-tooltip .arrow::before{
  border-top-color: #8181A5;
}
.subscribe-tooltip-subscribed .arrow::before{
  border-top-color: rgba(var(--primary_rgb), 0.2);
}
.subscribe-tooltip-subscribed .tooltip-inner{
  padding: 5px 15px;
  font-weight: bold;
  font-size: 14px;
  background: rgba(var(--primary_rgb), 0.2);
  color: var(--primary);
  border-radius: 8px;
}

.info-bubble{
  background: var(--primary);
  border-radius: 100%;
  min-width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  font-style: italic;
  line-height: 0;
  font-family: auto;
  display: flex;
}

.info-tooltip .tooltip-inner{
  min-width: 30px;
  min-height: 18px;
  padding: 5px 10px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  background: var(--primary);
}

.info-tooltip.bs-tooltip-bottom .arrow::before{
  border-bottom-color: var(--primary);
}

.info-tooltip.bs-tooltip-top .arrow::before{
  border-top-color: var(--primary);
}

.info-tooltip.bs-tooltip-right .arrow::before{
  border-right-color: var(--primary);
}

.info-tooltip.bs-tooltip-left .arrow::before{
  border-left-color: var(--primary);
}

.is_current-tooltip .tooltip-inner{
  background: #4DF189;
}

.is_current-tooltip.bs-tooltip-bottom .arrow::before{
  border-bottom-color: #4DF189;
}

.is_current-tooltip.bs-tooltip-top .arrow::before{
  border-top-color: #4DF189;
}

.is_current-tooltip.bs-tooltip-right .arrow::before{
  border-right-color: #4DF189;
}

.is_current-tooltip.bs-tooltip-left .arrow::before{
  border-left-color: #4DF189;
}

.is_previous-tooltip .tooltip-inner{
  background: #5E81F4;
}

.is_previous-tooltip.bs-tooltip-bottom .arrow::before{
  border-bottom-color: #5E81F4;
}

.is_previous-tooltip.bs-tooltip-top .arrow::before{
  border-top-color: #5E81F4;
}

.is_previous-tooltip.bs-tooltip-right .arrow::before{
  border-right-color: #5E81F4;
}

.is_previous-tooltip.bs-tooltip-left .arrow::before{
  border-left-color: #5E81F4;
}

.is_previous2-tooltip .tooltip-inner{
  background: #F4BE5E;
}

.is_previous2-tooltip.bs-tooltip-bottom .arrow::before{
  border-bottom-color: #F4BE5E;
}

.is_previous2-tooltip.bs-tooltip-top .arrow::before{
  border-top-color: #F4BE5E;
}

.is_previous2-tooltip.bs-tooltip-right .arrow::before{
  border-right-color: #F4BE5E;
}

.is_previous2-tooltip.bs-tooltip-left .arrow::before{
  border-left-color: #F4BE5E;
}

.btn-icon{
  background: rgba(129, 129, 165, 0.1);
  border: 1px solid #ECECF2;
  color: #8181A5;
  padding: 4px;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
}

.btn-icon.dropdown-btn{
  background: white;
}

.notification-btn{
  background: none;
  padding: 5px 7px;
  position: relative;
}

.notification-btn.subscribed{
  background: var(--primary_opacity);
  border-radius: 8px;
}

.notification-btn.subscribed:after {
  content: '✔';
    position: absolute;
    left: calc(100% - 18px);
    top: 4px;
    width: 14px;
    height: 14px;
    font-size: 10.5px;
    font-weight: bolder;
    text-align: center;
    background: #f8f8f8;
    color: white;
    background: #02ff5f;
    border-radius: 50%;
}

.select-contact-sms-modal{
  top: 0;
}

.select-contact-sms-modal .modal-header{
  background: var(--primary);
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.select-contact-sms-modal .modal-dialog{
  width: 60%;
  max-width: 60%;
}
.select-contact-sms-modal .modal-content{
  border-radius: 12px
}
.checkContact{
  color:var(--primary)
}
.btn-xs{
  padding: 3px 7px;
  font-size: 12px !important;
  color: #fff !important;
  margin-right: 0 !important;
}
.user-dashboard .carousel-container{
  padding: 5px 15px;
}

 .user-dashboard-tooltip.tooltip{
  top: 65px !important;
  left: 25px !important;
  width: 186px;
  padding: 5px;
}
.user-dashboard-tooltip .tooltip-inner{
  box-shadow: 0px 5px 13px rgb(0 0 0 / 20%);
  border-radius: 0px 18px 18px 18px;
  padding: 5px;
  font-size: 125%;
}

.user-dashboard-tooltip.tooltip .arrow{
  display: none
}
.tooltip-call-made .tooltip-inner {
  background-color: #6c757d;
 }

.tooltip-time-spent .tooltip-inner {
  background-color: #007bff;
}
.tooltip-time-spent-phone .tooltip-inner {
  background-color: #28a745;
}
.user-dashboard .carousel-cells{
  /* display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important; */
}

body.has-warning-bar .page-sidebar{
  top: 40px;
}

body.has-warning-bar .page-container{
  padding-top: 40px;
}

  .ngx-file-drop__drop-zone{
    height: 400px !important;
    margin-top: 50px !important;
  }
  .ngx-file-drop__content{
    height: 400px !important;
  }

  .intercom-lightweight-app .intercom-lightweight-app-launcher{
    right: 5px !important;
    bottom: 5px !important;
  }
  .intercom-launcher-frame{
    right: 5px !important;
    bottom: 5px !important;
  }

.text-ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  display: block;
}

.input-container-left.success{
  background: rgba(94, 129, 244, 0.1) !important;
}
.input-container-right.success{
  background: rgba(94, 129, 244, 0.1) !important;
}

.input-container-left.error{
  background: rgba(255, 128, 139, 0.08) !important;
}

.input-container-right.success{
  background: rgba(94, 129, 244, 0.1) !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #5E81F4 !important;
  height: 3px !important;
}

.duplicates .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #F4BE5E !important;
  height: 3px !important;
}

.error .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #FA5959 !important;
  height: 3px !important;
}

.graph-content .mat-tab-header{
  margin-bottom: 40px !important;
}
.example-radio-button .mat-radio-label-content {
  color: #1C1D21 !important;
  font-size: 14px !important;
  line-height: 32px;
}
.example-radio-button .mat-radio-inner-circle {
  background-color: var(--primary) !important;
}

.fw-bold{
  font-weight: bold;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary) !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  border-color: var(--primary) !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--primary);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary);
}
p{
  color: #1C1D21;
}

.mat-elevation-z8{
  box-shadow: none !important;
  border: 1px solid #ECECF2 !important;
}

.mat-sort-header-content{
  font-size: 12px !important;
  line-height: 18px !important;
  color: #8181A5 !important;
  margin: auto;
}
.fix-error .new-data-th .mat-sort-header-content{
  margin: 0 !important;
  padding-left: 15px;
}

td.mat-cell{
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
  color: #1C1D21 !important;
  font-family: 'Lato';
}

th.mat-cell{
  font-family: 'Lato';
}

.new-records{
  background: rgba(39, 174, 96, 0.15);
}

.new-records span{
  background: linear-gradient(0deg, rgba(39, 174, 96, 0.72), rgba(39, 174, 96, 0.72)), #F5F5FA;
  border-radius: 24px;
  padding: 4px 20px;
  color: white !important;
}

.update-records{
  background: rgba(244, 190, 94, 0.15);
}

.update-records span{
  background: linear-gradient(0deg, #F4BE5E, #F4BE5E), #F5F5FA;
  border-radius: 24px;
  padding: 4px 20px;
  color: white !important;
}

.error-th{
  background: #EEEEEE;
}

.new-data-th{
  background: rgba(39, 174, 96, 0.2);
}

.error-th span{
  background: linear-gradient(0deg, #979797, #979797), #F5F5FA;
  border-radius: 24px;
  padding: 4px 20px;
  color: white;
}
.cancel-ic{
  color: #FA5959 !important;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.error-data{
  background: linear-gradient(0deg, #979797, #979797), #F5F5FA;
  border-radius: 24px;
  padding: 4px 20px;
  color: white;
  font-weight: 400 !important;
}

.import-container td.status{
  font-weight: 400 !important;
}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 15px !important;
}

.status .mat-button{
  width: 36px !important;
  min-width: 36px !important;
  height: 36px !important;
  text-align: center;
  padding: 0px !important;
  border: 1px solid #ECECF2 !important;
}

.custom-dialog .mat-dialog-container {
	padding-top: 0px !important;
}

.custom-dialog .mat-dialog-actions {
	margin:0px -24px;
  margin-bottom: -24px;
  box-shadow: 0px -4px 10px -2px rgba(0, 0, 0, 0.15);
  padding: 20px 24px;
}

.custom-dialog .content{
  padding: 40px;
  font-family: 'Lato';
  margin-bottom: 80px;
}

.dialog-header{
  background: #5E81F4;
  padding: 25px;
  box-shadow: 0px 3px 9px -1px rgba(0, 0, 0, 0.15);
  margin-right: -24px;
  margin-left: -24px;
}

.dialog-header .iconify{
    font-size: 30px;
    line-height: 32px;
    color: white;
    font-weight: bold;
}

.dialog-header .title-popup, .dialog-header .edit {
  color: white;
  font-size: 25px;
  line-height: 32px;
}

button.confirm{
  background: #5E81F4;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  padding: 16px 55px;
}

.input-new-data input{
  border: none !important;
  outline: none !important;
}

.new-data-td{
  text-align: left !important; 
  padding-left: 15px !important; 
}

.new-data-td.mat-cell{
  font-weight: 400 !important;
}

.import-priority .priority{
  border: none !important;
  outline: none !important;
  font-family: 'Lato';
  font-weight: bold !important;
}

.secondary-sidebar .first-section .select p{
  color: #5E81F4 !important;
}
.notify-error{
  background: rgba(255, 128, 139, 0.15) !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color: var(--primary) !important;
}

.mat-menu-panel{
  min-height: auto !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px !important;
}

.from-script.mat-menu-panel{
  min-height: auto !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px !important;
  max-height: 300px !important;
  background-color: var(--primary) !important;
}

.from-script.mat-menu-panel button{
  color: white !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 21px !important;
  height: 35px !important;
}

.communicationType{
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 32px;
  color: #8181A5;
}

.simpleSelect{
  background: linear-gradient(301.12deg, rgba(6, 54, 34, 0.05) 1.24%, rgba(77, 241, 137, 0.05) 88.92%), #FFFFFF;
  border: none;
  outline: none;
}

.light-gray-bg{
  background: linear-gradient(301.12deg, rgba(6, 54, 34, 0.05) 1.24%, rgba(77, 241, 137, 0.05) 88.92%), #FFFFFF !important;
}

.mat-menu-content{
  padding: 10px 0px 10px 0px !important;
}

.leaflet-popup{
  left: -41px !important;
  bottom: -20px !important;
}

.leaflet-popup-close-button{
  display: none !important;
}

.leaflet-popup-tip-container{
  margin-left: -177px !important;
}

.green-dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #4DF189;
}

.blue-dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5E81F4;
}
.acid-green-dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #BAB700;
}
.blue-green-dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #589C7C;
}

.custom-popup-content{
  display: flex;
  width: 350px;
  flex-direction: column;
  gap: 10px;
}

.custom-popup-content .middle{
  gap: 20px;
}

.custom-popup-content .r{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  background: #464A5F;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.custom-popup-content .name strong{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1C1D21;
}

.custom-popup-content .name{
  flex-shrink: 0;
}

.custom-popup-content .mail{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #1C1D21;
}

.custom-popup-content .name .mute{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8181A5;
}

.custom-popup-content .mute-div{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  background: #F5F5FA;
  align-self: center;
  border-radius: 8px;
  padding: 0px 10px;
  color: #8181A5;
}

.leaflet-popup-content{
  width: auto !important;
}

.map-type-option .example-margin .mat-checkbox-label{
  color: #5E81F4 !important;
  line-height: 46px !important;
  font-size: 18px;
  font-weight: 700;
}

.map-type-option .example-margin .mat-checkbox-frame {
  border-color: #5E81F4;
}

.map-type-option label{
  margin-bottom: 0px;
}
/* 
.leaflet-top.leaflet-left{
  display: none;
} */

.border-right {
  border-right: 1px solid #8181A5 !important;
}

.filter-bar, .override-tag-rules {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.filter-bar::-webkit-scrollbar, .override-tag-rules::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary) !important;
}

.abs-filter-position {
  position: absolute;
  top: 9px; 
  z-index: 855;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}

.ex-title-list .ng-select-container{
  color: var(--primary);
}

.custom-mat-menu-panel{
  max-width: none !important;
  border-radius: 12px !important;
}

.custom-mat-menu-panel .mat-menu-content{
  padding-top: 10px !important;
  margin-bottom: 10px !important;
}

.custom-origin{
  transform-origin: 0px 0px;
}

.leaflet-top, .leaflet-bottom {
  z-index: 854;
}

.icon-ng-select .ng-select .ng-select-container, .range-slider-flex .ng-select .ng-select-container{
  border: none;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #1C1D21;
}

.ng-option-label{
  font-family: 'Lato';
  font-size: 14px;
  line-height: 17px;
  color: #1C1D21;
}

.rule-select .ng-select .ng-select-container{
  border: none !important;
  background: rgba(94, 129, 244, 0.15);
  border-radius: 12px;
}

.save-option .ng-select .ng-select-container{
  border: none !important;
  background: #8181A5;
  border-radius: 8px;
}

.save-option .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent;
}

.save-option .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  border-color: transparent transparent white;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 6px; 
}

.subscription-plan-panels .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  border: 1px solid #ECECF2;
  border-radius: 12px;
}

.cancel-filter-span{
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  cursor: pointer;
  color: #FF808B;
}

.remove-if-clause{
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #FF808B;
}

.select-unselect-all label{
  margin-bottom: 0px;
}

.pointer{
  cursor: pointer;
}
body.extended .page-sidebar.page-right-sidebar-menu{
  right: 0px !important;
  left: auto !important;
  width: auto !important;
  z-index: 860 !important;
  position:absolute;
  height: 100%;
  overflow-y: hidden;
}
body.extended .sidebar-menu.right-side-menu{
  width: 83px;
}
body.extended .sidebar-menu.right-side-menu.extend {
  width: 100%;
}
body.extended .page-sidebar .right-side-menu .sidebar-menu-items li a span {
  font-size: 10px;
}
.page-sidebar.page-right-sidebar-menu{
  right: 0px !important;
  left: auto !important;
  width: auto;
  z-index: 860 !important;
  position:absolute;
  height: 100%;
  overflow-y: hidden;
}

.transition-animation {
  transition: all 0.5s ease;
}

.toggle-sidebar{
  position: fixed;
  z-index: 10;
  display: flex;
  height: 56px;
  width: 56px;
  top: 73px;
  right: 0px;
  align-items: center;
  justify-content: right;
}

/* .mat-icon-button{
  width: 30px !important;
  height: 30px !important;
} */

.toggle-sidebar span{
  cursor: pointer;
  border-radius: calc(56px - 8px - 10px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin: 8px 0 8px 8px;
  padding: 10px 0px 6px 8px;
  width: calc(56px - 13px - 10px);
  background-color: #fff;
  display: inline-block;
  transition: all .3s cubic-bezier(.4,0,.2,1);
  box-shadow: 0 1px 1px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.toggle-sidebar span i{
  font-size: 20px;
  font-weight: bold;
  transform: none;
}

.toggle-sidebar span:hover{
  width: 56px;
}

.show_right_side_bar .toggle-sidebar{
  right: 20px;
}

.show_right_side_bar .toggle-sidebar span{
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: none;
}

.show_right_side_bar .toggle-sidebar span:hover{
  background: rgba(129,129,165,0.1);
}

.show_right_side_bar .toggle-sidebar span i{
  transform: rotate(180deg);
  line-height: 40px;
}

.main-page-wrapper{
  display: flex;
  flex: 1;
}

.main-page-wrapper div:first-child{
  width: calc(100% - 83px);
}

.show_right_side_bar .page-body{
  padding-right: 0px !important;
}

.page-right-sidebar-menu{
  z-index: 2 !important;
}

.sort-filter .ng-select .ng-select-container{
  border: none !important;
  font-size: 14px !important;
} 

ngb-popover-window.bs-popover-bottom-right>.arrow, ngb-popover-window.bs-popover-top-right>.arrow {
  right: 4px !important;
}

.import-dashboard .mat-tab-header{
  padding: 0px 28px !important;
}

.ng-select.custom .ng-select-container{
  border: none !important;
  background-color: transparent !important;
}

.parties-tooltip .tooltip-inner {
  background-color: #007bff;
  font-size: 150%;
  padding: 5px 10px;
}
.parties-tooltip .tooltip-arrow{
  background:  #007bff;
}
.parties-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #007bff;
}
.parties-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #007bff;
}
.parties-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #007bff;
}
.parties-tooltip.bs-tooltip-top .arrow::before,
.parties-tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before{
  border-top-color: #007bff;
}
.cdk-overlay-container{
  z-index: 1051 !important;
}

.subscription-plan-panels .ng-select .ng-select-container{
  border: none !important;
  border-bottom: 1px solid #F0F0F3 !important;
}
.ng-dropdown-panel .scrollable-content{
  background: #fff;
}
.mapping-close-menu{
  right: 10px;
  font-size: 20px;
  position: absolute;
  color: var(--danger) !important;
  text-decoration: none !important;
}

.invoice-details-item .mat-expansion-panel-body {
  padding: 0px !important;
}

.invoice-details-item .menu-group-name{
  font-size: 16px !important;
  line-height: 27px !important;
  color: #1C1D21 !important;
  font-weight: 700;
}

.form-control:focus {
  color: #495057;
  background-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #D8D8D8;
  outline: 0;
  box-shadow: none;
}

.invoice-configuration .form-control{
  font-size: 14px !important;
}

.invoice-configuration select{
  padding-left: 0px !important;
}
.stage-description-tooltip{
  padding: 8px;
  background: #000;
  color: #fff;
  padding-right: 20px;
}
.stage-description-tooltip .popover-body{
  color: #fff;
}
.stage-description-tooltip .arrow::after{
  border-bottom-color:#000 !important
}
.stage-description-tooltip .close-icon{
  color: #fff;
  font-size: 15px;
  right: 0;
  position: absolute;
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
  background-color: var(--primary);
}
.email-campaign-form .mat-step-header{
  pointer-events: none !important;
}
.client-payments-select.ng-select .ng-select-container {
  border: none !important;
}
.donut-graph .apexcharts-datalabel-label {
  display: block !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
#area-chart .apexcharts-toolbar,
.transaction.graph-section .apexcharts-toolbar{
  display: none !important;
}
.comment table{
  width: 100% !important;
}
.comment-text table{
  width: 100% !important;
}

.quicksign{
  width:500px;
  height:50px;
 position:relative;
  border-radius:20px;
  -o-border-radius:20px;
  -moz-border-radius:20px;
  -webkit-border-radius:20px;
   border:1px solid rgba(1,1,1,0.0);
     -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  cursor:pointer;

}
 .quicksign img{ width:100%; height:100%;
 position:absolute;
 left:0px;
 top:0px;}
.quicksign:hover{
  border:1px solid rgba(1,1,1,0.3);
    -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;

  background-color:rgba(1,1,1,0.1);
}
#quicksignwrap{
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  z-index:99999;
  display:none;
}
#quicksign{
  position:absolute;
  top:0px;
  left:0px;

  background-color:#fff;
  border-radius:20px;
  -o-border-radius:20px;
  -moz-border-radius:20px;
  -webkit-border-radius:20px;
  box-shadow:0px 0px 10px 1px #111 inset;
  -o-box-shadow:0px 0px 10px 1px #111 inset;
  -moz-box-shadow:0px 0px 10px 1px #111 inset;
  -webkit-box-shadow:0px 0px 10px 1px #111 inset;
  border:2px solid #fff;
  width:500px;
  height:100px;
   cursor:  crosshair;
  cursor:  url('/assets/img/cur.png');

}
#qsdiag{
   position:absolute;
  top:0px;
  left:0px;
  background: url('/assets/img/diag.png');
  background-color:#fff;
  border-radius:20px;
  -o-border-radius:20px;
  -moz-border-radius:20px;
  -webkit-border-radius:20px;
  box-shadow:0px 0px 10px 1px #111 inset;
  -o-box-shadow:0px 0px 10px 1px #111 inset;
  -moz-box-shadow:0px 0px 10px 1px #111 inset;
  -webkit-box-shadow:0px 0px 10px 1px #111 inset;
  border:2px solid #fff;
  width:500px;
  height:100px;
  display:none;
}

#quicksignback{
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  background: rgb(125,126,125); /* Old browsers */
/* IE9 SVG, needs conditional override of 'filter' to 'none' */
background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM3ZDdlN2QiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMGUwZTBlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
background: -moz-radial-gradient(center, ellipse cover,  rgba(125,126,125,1) 0%, rgba(14,14,14,1) 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(125,126,125,1)), color-stop(100%,rgba(14,14,14,1))); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(125,126,125,1) 0%,rgba(14,14,14,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover,  rgba(125,126,125,1) 0%,rgba(14,14,14,1) 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover,  rgba(125,126,125,1) 0%,rgba(14,14,14,1) 100%); /* IE10+ */
background: radial-gradient(ellipse at center,  rgba(125,126,125,1) 0%,rgba(14,14,14,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
opacity:0.8;
}
#cleartext{
  width:30px;
  height:30px;
  background:#444;
  position:absolute;
  top:0px;
  left:0px;
  margin-top:10px;
  background: url('/assets/img/cleartext.png')no-repeat;
   cursor:pointer;
}
 #qssubmit{
  width:30px;
  height:30px;
  background:#444;
  position:absolute;
  top:0px;
  left:0px;
  margin-top:10px;
  background:  url('/assets/img/submit.png')no-repeat;
  cursor:pointer;
}
#qssave{
  background:#555;
  border:5px solid #666;
  border-radius:10px;

  font-weight:bold;
  color:#eee;
  cursor:pointer;
}
#quicksigndoc{ }
#savefileform{
 background: none repeat scroll 0 0 #999999;
  border-radius: 10px 10px 10px 10px;
  bottom: 10px;
  height: 30px;
  left: 10px;
  padding: 5px;
  padding-left:20px;
  padding-right:20px;
  position: fixed;
  width: 300px;

}

#savefileform h3{
 margin-top:2px;
 margin-left:27%;
 color:#eee;
}
.pl-97{
  padding-left: 97px;
}
.sms-variable-popover{
  width: 364px;
  max-width: 364px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 12px;
  background: #FFFFFF;
  padding: 22px;
}
button{
  border: none;
}
.form-builder-variable-popover{
  width: 280px;
  max-width: 280px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 20px;
}
.variable-box-wrapper{
  max-height: 400px;
  overflow: auto;
}
.mat-tab-body-wrapper{
  height: 100%;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
  font-weight: bold;
}
.allow-user-popover{
  padding: 12px;
  left: 0px !important;
  min-width: 330px !important;
}

.ng-dropdown-panel.board-select .ng-dropdown-panel-items .ng-optgroup, 
.ng-dropdown-panel.board-select .ng-dropdown-panel-items .ng-option{
  white-space: normal !important;
}

.add-template-modal .modal-dialog {
  width: 95% !important;
  max-width: 95% !important;
}

ng-select.form-control{
  padding: 0;
  border: none;
}

.tech-support-modal .modal-dialog{
  max-width: 659px;
}
.new-form-modal .modal-dialog{
  max-width: 95vw;
  height: 90vh;
  overflow-y: hidden;
}
.modal-content{
  border-radius: 12px;
}
.action-notification-config-modal .modal-dialog,
.action-sms-config-modal .modal-dialog,
.action-email-config-modal .modal-dialog{
  max-width: 1292px;
  width: 95vw;
  height: 95vh;
  overflow-y: hidden;
}

.action-add-contact-config-modal .modal-dialog{
  max-width: 1722px;
  width: 95vw;
  height: 95vh;
  overflow-y: hidden;
}

.myCustomDataSetModalClass .modal-dialog {
  width: 942px !important;
  max-width: 95vw !important;
  height: 748px !important;
  max-width: 95vh !important;
  display: flex;
  flex-direction: column;
}
.label-inner-html p{
  margin-bottom: 0px;
}
.ql-align-center{
  text-align: center;
}
.ql-align-right{
  text-align: right;
}
.ql-align-left{
  text-align: left;
}

.ql-snow .ql-picker-options .ql-picker-item {
  font-family: attr(data-value);
}
.ql-snow .ql-picker-options .ql-picker-item::before{
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

.export-modal > .modal-dialog{
  max-width: 1300px !important;
  width: 90% !important;
}

.cm-gutterElement{
  margin-top: 0px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  border: none !important;
  height: 24px;
  margin-bottom: 0px !important;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: var(--primary);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 24px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

td:has(> span.e-choixcell),  
td:has(> span.elector-attempts) { 
  padding-right: 0px !important; 
}
.elector-export-modal .modal-dialog-centered{
  max-height: 94vh;
  max-width: 90vw;
}

.approval-warning-panel{
  background: rgb(255 244 226) !important;
  border: 1px solid #F4BE5E;
  border-radius: 8px;
}

.warning-icon{
  color: #F4BE5E;
}

.mat-dialog-container{
  background: inherit;
}